import React, { useState, useContext, useEffect } from "react";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { useNavigate, Link } from "react-router-dom";
import { StateContext } from "../contexts/State";
import TextSVGContainer from "../components/svg/TextSVGContainer";
import EmailSVG from "../components/svg/EmailSVG";
import LockSVG from "../components/svg/LockSVG";
import CloseSVG from "../components/svg/CloseSVG";
import { BarLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";

/**
 * Login Page
 * Simple page with input validation for login
 * @returns Login Page
 */
const Login = () => {
  const { state, dispatch } = useContext(StateContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(
    "There was a problem with logging in.  Please ensure your email or password was correct and that you have an account."
  );
  const userNameLabel = "Email";
  const passwordLabel = "Password";
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();
  return (
    <main className="flex flex-col xl:flex-row mt-3 mr-3 h-full relative">
      <Toaster position="top-center" reverseOrder={false} toastOptions={{
        className: 'text-tssBlack-500 font-light'
      }} />

      <Link to={"/"} className="xl:hidden w-20 mx-auto mt-36 mb-10">
        <img src="/tss-nav.png" alt="" />
      </Link>
      {!loading ? (
        <form
          className="flex flex-col p-6 mx-auto xl:mt-[15.375rem] xl:m-auto w-[25rem] border-tssGray-500 border rounded-2xl"
          onSubmit={async (e) => {
            e.preventDefault();
            return login(email, password).catch((e) => {
              throw Error(e);
            });
          }}
        >
          <div className="flex flex-col items-start">
            <p className="text-tssBlack-500 text-2xl font-normal mb-2">
              Log into your account
            </p>
            <p className="text-tssGray-600 text-base font-light">
              Enter your credentials to log in
            </p>
            <div className="w-full flex flex-col gap-4 items-start mt-6">
              <TextInput
                className="w-full"
                id={"email-input-field"}
                label={userNameLabel}
                placeholder={"Enter Email"}
                defaultValue={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                  setEmailErrorMsg("");
                }}
                onBlur={() => {
                  setPasswordErrorMsg("");
                  setValidationError(false);
                }}
                errorMessage={emailErrorMsg}
                type="email"
                name="email"
                hasError={emailError || validationError}
                setHasError={setEmailError}
              />
              <TextInput
                className="w-full"
                id="password-input-field"
                label={passwordLabel}
                type="password"
                placeholder={"Enter Password"}
                defaultValue={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onBlur={() => {
                  setPasswordErrorMsg("");
                  setValidationError(false);
                }}
                hasError={validationError}
                errorMessage={passwordErrorMsg}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center justify-between w-full text-tssBlack-500 text-sm font-light">
            <div className="my-5 flex items-center justify-between gap-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="appearance-none w-6 h-6 rounded-lg border-tssGray-500 border-[1.25px] checked:bg-tssBlue-500 checked:border-0 relative peer shrink-0"
                  onChange={(e) => {
                    setRememberMe(e.target.checked);
                  }}
                />
                <svg
                  className="
                absolute
                w-4 h-4 mt-1 ml-1 mb-[2px]
                hidden peer-checked:block
                pointer-events-none
                "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <span>Remember Me</span>
            </div>
            <a
              href={process.env.REACT_APP_PASSWORD_RESET_URL}
              target="_blank"
              rel="noreferrer"
              className=""
            >
              Forgot Password
            </a>
          </div>
          <Button
            id="login-button"
            className="rounded-lg"
            type="submit"
            errorCallback={loginFailed}
          >
            Login
          </Button>
        </form>
      ) : (
        <div className="flex flex-col items-center m-auto px-10">
          <div className="h-96 flex flex-col items-center gap-4">
            <img src="/tss-nav.png" alt="" />

            <h1 className="text-blueprimary-600 text-3xl mt-8 mb-4">
              Signing in...
            </h1>
            <BarLoader color="#4383cb" />
          </div>
        </div>
      )}

      {!loading && (
        <div className="hidden xl:flex  max-w-[600px] flex-col items-start gap-3 bg-tssBlue-200 pl-24 pr-8 pt-8 rounded-xl overflow-hidden relative">
          <Link
            to={"/"}
            className="self-end bg-[#8D939C1F] rounded-full w-8 h-8 flex items-center justify-center"
          >
            <CloseSVG className={"text-[#8D939C]"} />
          </Link>
          <img src="/tss-nav.png" alt="tss logo" />
          <h1 className="font-normal text-4xl leading-[3.375rem] text-tssBlack-500 max-w-md">
            Dynamic scheduling for tiered intervention systems in schools
          </h1>
          <h4 className="font-light text-xl text-tssGray-600">
            Tiered System Support
          </h4>

          <div className="relative w-full h-0">
            <img
              src="/calendar-preview-2.png"
              alt="tss calendar"
              className="absolute -top-60 -right-12 translate-x-1/2 translate-y-1/2 rounded-xl"
              style={{ width: "auto", maxWidth: "1000px" }} // Ensure the image maintains its original dimensions
            />
          </div>
        </div>
      )}
    </main>
  );

  /**
   * login Function
   * Attempts to login
   * If login succeeds but the user/school doesn't exist
   * it automatically logs them out and gives them an error
   * @param email Email to login
   * @param password Password to login
   * @returns
   */
  async function login(email, password) {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "users/login",
        {
          type: "cors",
          method: "POST",
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );
      const json = await response.json();
      if (!response.ok) {
        loginFailed(response);
      } else {
        successfulRedirect(json);
      }
    } catch (e) {
      setLoading(false);
      loginFailed(e);
    }
  }

  async function resetPassword(email) {}

  // Redirects to dashboard if it successfully logged in.
  async function successfulRedirect(data) {
    dispatch({ type: "STORE_USER_DATA", payload: data });
    localStorage.setItem("currentUser", JSON.stringify(data));
    if (rememberMe) {
      document.cookie =
        "tss_remember_me=keep_logged_in; max-age=31536000; SameSite=lax; path=/";
    }
    navigate("/dashboard");
  }

  function loginFailed(response) {
    setPasswordErrorMsg(
      "There was a problem with logging in.  Please ensure your email or password was correct and that you have an account."
    );
    console.error(response);
    setValidationError(true);
    setLoading(false);
  }
};
export default Login;
