import { useContext, useEffect, useCallback } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { StateContext } from "./contexts/State";
import { useIdleTimer } from "react-idle-timer";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-hot-toast";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { state, dispatch } = useContext(StateContext);

  const logoutUser = useCallback(async () => {
    if (
      currentPath !== "/" &&
      currentPath !== "/login" &&
      currentPath !== "login"
    ) {
      toast("Logged out due to inactivity.");
      navigate("/");
      // dispatch({ type: "REMOVE_ALL_DATA" });
      // localStorage.clear("currentUser");
      // document.cookie =
      //   "tss_remember_me=keep_logged_in; max-age=0; SameSite=lax; path=/";
    } else {
      return;
    }
  }, [currentPath, navigate]);

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 30, //  30 minutes,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'],
    onIdle: logoutUser,
    debounce: 500,
  });

  const isTokenExpired = () => {
    const loggedInUser = localStorage.getItem("currentUser");
    const foundUser = JSON.parse(loggedInUser);
    const token = foundUser?.token;
    if (!token) return true;

    const { exp } = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return exp < currentTime;
  };

  useEffect(() => {
    if (isTokenExpired() && currentPath !== "/login") {
      console.error("token is expired");
      logoutUser();
    }
    const handleVisbility = () => {
      if (!document.hidden && isTokenExpired()) {
        console.error("document not hidden, token expired");
        logoutUser();
      } else if (!document.hidden) {
        console.log("resetting, user is active");
        reset();
      }
    };

    document.addEventListener("visibilitychange", handleVisbility);

    return () => {
      document.removeEventListener("visibilitychange", handleVisbility);
    };
  }, [currentPath, logoutUser, reset]);

  return (
    <div className="App">
      <Outlet />
    </div>
  );
}

export default App;
